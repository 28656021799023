/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import './theme.scss';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import '../../../libs/shared/src/lib/styles/common.scss';

/* You can add global styles to this file, and also import other style files */
$primary: map-get($theme, primary);
$accent: map-get($theme, accent);

$foreground: map-get($theme, foreground);
$background: map-get($theme, background);

// layout

.td-layout-nav-content {
  background-color: #f5f5f5;
}

// card over

.card-over-wrapper {
  z-index: 2;
  margin: -64px;
  margin-left: 0;
  margin-right: 0; // [layout-fill]
  width: 100%;
  min-height: 100%;
  height: 100%;
  @media (min-width: 600px) {
    // [layout="row"]
    flex-direction: row; // [layout]
    box-sizing: border-box;
    display: flex; // [layout-align]="center start"
    align-items: flex-start;
    align-content: flex-start;
    justify-content: center;
    .card-over {
      max-height: 100%;
      box-sizing: border-box;
    }
  }
  @media (max-width: 600px - 1) {
    .card-over {
      max-width: 100% !important;
    }
  }
}

// Active icon color in list nav
mat-nav-list {
  [mat-list-item].active {
    mat-icon[matListItemIcon] {
      background-color: mat.get-color-from-palette($accent);
      color: mat.get-color-from-palette($accent, default-contrast);
    }
    mat-icon[matListIcon] {
      color: mat.get-color-from-palette($accent);
    }
  }
}

// Active top nav
div[td-toolbar-content] {
  nav {
    a {
      span {
        line-height: 4;
        display: block;
      }
      &:not(.active) {
        color: white;
      }
      &.active {
        font-weight: bold;
        color: mat.get-color-from-palette($accent);
        span {
          border-bottom: 2px solid mat.get-color-from-palette($accent);
        }
      }
    }
  }
}
// // Links
// a {
//   text-decoration: none !important;
//   color: mat.get-color-from-palette($accent);
// }

// Snackbar
.mat-simple-snackbar-action {
  color: mat.get-color-from-palette($accent) !important;
}

// NGX Charts
[ngx-charts-axis-label] text {
  fill: mat.get-color-from-palette($foreground, secondary-text);
}
.tick text {
  fill: mat.get-color-from-palette($foreground, disabled);
}
.gridline-path {
  &.gridline-path-horizontal,
  &.gridline-path-vertical {
    stroke: rgba(black, 0.06);
  }
}
.legend-title-text {
  color: mat.get-color-from-palette($foreground, secondary-text);
}

.drag-n-drop-placeholder {
  background: #ccc;
  border: dotted 3px #999;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
