@use '@angular/material' as mat;
@use '../../../node_modules/@covalent/core/theming/all-theme' as cov;

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-elevation-z7 {
  box-shadow: 0 4px 5px -2px #0003, 0 7px 10px 1px #00000024, 0 2px 16px 1px #0000001f;
}

@include mat.core();

// Include the core styles for Covalent
@include cov.covalent-core();

// Include covalent utility classes
@include cov.covalent-utilities();

// Include flex layout classes
@include cov.covalent-layout();

// Include covalent color classes
@include cov.covalent-colors();

$primary: mat.define-palette(mat.$blue-grey-palette, 900, 100, 800);
$accent: mat.define-palette(mat.$yellow-palette, 700, 200, 900);
$warn: mat.define-palette(mat.$red-palette, 600, 200, 900);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

@include mat.all-component-themes($theme);
@include mat.typography-hierarchy($theme);
@include cov.covalent-theme($theme);

.tc-primary {
  color: mat.get-color-from-palette($primary);
}
.tc-accent {
  color: mat.get-color-from-palette($accent);
}

.bgc-primary {
  background-color: mat.get-color-from-palette($primary);
}
.bgc-accent {
  background-color: mat.get-color-from-palette($accent);
}
