// Container
.container {
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.cursor-pointer:hover {
  cursor: pointer;
}

//logo
mat-toolbar .mat-icon.mat-icon-logo {
  width: 200px;
  height: 60px;
}
